/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable */
import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import TextField from '@common_textfield';
import { FormHelperText } from '@material-ui/core';
import Button from '@common_button';
import PasswordInput from '@common_passwordinput';
import useStyles from '@modules/login/pages/default/components/style';
import { useRouter } from 'next/router';
import Link from 'next/link';
import LanguageSelect from '@modules/theme/layout/components/languageSelect';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Login = (props) => {
    const classes = useStyles();
    const {
        formik,
        dataConfig,
        storeLogo,
        t,
        recaptcha,
    } = props;
    const router = useRouter();

    const handleRoute = () => {
        const email = localStorage.getItem('GET_EMAIL');

        if (email) {
            router.push('/registervendor/otp');
        } else {
            router.push('/registervendor');
        }
    };

    return (
        <div className={clsx(classes.loginContainer)}>
            <div className={classes.containLeft}>
                <div className={classes.headerLogin}>
                    <img
                        alt="logo"
                        src={storeLogo?.logo || '/assets/img/swiftoms_logo_expanded.png'}
                        style={{ maxHeight: 52, cursor: 'pointer' }}
                        onClick={() => router.push('/')}
                    />
                    <LanguageSelect setLanguage={false} />
                </div>
                <div className={classes.loginContent}>
                    <Typography variant="h6" gutterBottom className={classes.textTitle} id="h6_masuk_ke_akun_anda">
                        {t('login:signIn')} 
                    </Typography>
                    <form onSubmit={(e) => formik.handleSubmit(e)}>
                        <div className="row center-xs start-sm">
                            <div className={clsx('col-xs-12 col-sm-12', classes.formField)}>
                                <div>
                                    <span className={clsx(classes.label, classes.labelRequired)}>{t('login:email')}</span>
                                </div>
                                <TextField
                                    name="email"
                                    InputProps={{ id: 'login_email' }}
                                    variant="outlined"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    className={classes.textInput}
                                    error={!!(formik.touched.email && formik.errors.email)}
                                    helperText={<FormHelperText id="email_helper_text">{(formik.touched.email && formik.errors.email) || ''}</FormHelperText>}
                                    FormHelperTextProps={{ id: 'email_helper_text' }}
                                />
                            </div>
                            <div className={clsx('col-xs-12 col-sm-12', classes.formField)}>
                                <div>
                                    <span className={clsx(classes.label, classes.labelRequired)} htmlFor="filled-adornment-password">{t('login:password')}</span>
                                </div>
                                <PasswordInput
                                    InputProps={{ id: 'login_password' }}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    className={classes.textInput}
                                    error={!!(formik.touched.password && formik.errors.password)}
                                    helperText={<FormHelperText id="password_helper_text">{(formik.touched.password && formik.errors.password) || ''}</FormHelperText>}
                                    FormHelperTextProps={{ id: 'password_helper_text' }}
                                />
                            </div>
                            
                            {recaptcha.enable && (<GoogleReCaptchaProvider reCaptchaKey={recaptcha.sitekey} />)}

                            <div className={clsx('col-xs-12 col-sm-12', classes.formField)}>
                                <Button type="submit" variant="contained" className={classes.btnLogin} id="button_login">
                                    <span className={classes.btnLoginText}>{t('login:login')}</span>
                                </Button>
                                <Typography variant="h6" className={classes.btnTextForgot}>
                                    <Link href="/forgotpassword">
                                        <a>{t('login:forgotPassword')}</a>
                                    </Link>
                                </Typography>
                                {/* {dataConfig
                                    && (
                                        <Button onClick={handleRoute} variant="contained" className={classes.btnVendor}>
                                            <span className={classes.btnVendorText}>{t('login:createSellerAccount')}</span>
                                        </Button>
                                    )} */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className={classes.containRight}>
                <img alt="" src={storeLogo?.login_side_image || '/assets/img/swift-bg-login.png'} className={classes.rightImg} />
            </div>
        </div>
    );
};

export default Login;
