/* eslint-disable indent */
/* eslint-disable prefer-destructuring */
import {
    getToken, getStoreConfig, checkCustomerValidateOtp,
    getReCaptchaConfig, setDeviceInfo, getXSiteToken,
} from '@modules/login/services/graphql';
import { expiredToken } from '@config';
import { removeIsLoginFlagging, setLogin } from '@helper_auth';
import { useRouter } from 'next/router';
import Layout from '@layout';
import { regexEmail } from '@helper_regex';
import BackdropLoad from '@helper_backdropload';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import qs from 'querystring';
import * as UAParser from '@helpers/ua-parser';

import { clearChatCookies } from '@modules/vendorchat/helpers/index';

const { decryptRec } = require('./encryption');

const ContentWrapper = (props) => {
    const {
        Content, storeLogo, t, recaptcha,
    } = props;
    const router = useRouter();

    const [getCustomerToken] = getToken();
    const [setDevice] = setDeviceInfo();
    const [checkValidateOtp] = checkCustomerValidateOtp();

    const [getNewToken] = getXSiteToken();
    let queryString = null;
    let urlParams = null;
    let isAuth = null;

    if (typeof window !== 'undefined') {
        queryString = window.location.search;
        urlParams = new URLSearchParams(queryString);
        isAuth = urlParams.get('auth');
        isAuth = (isAuth) ? isAuth.replace(' ', '+') : isAuth;
    }

    const initialGeoLocation = {
        device: '',
        ip_address: '',
        location: '',
        user_agent: '',
    };

    const [geoLocationData, setGeoLocationData] = React.useState(initialGeoLocation);

    React.useEffect(() => {
        window.localStorage.clear();

        clearChatCookies();
        removeIsLoginFlagging();

        (async () => {
            let isSuccess = false;
            const userAgent = window.navigator.userAgent;
            const uaParser = new UAParser();
            const detectedBrowser = uaParser.getBrowser();
            const detectedOs = uaParser.getOS();
            let device = '';

            if (detectedBrowser.name) {
                device = detectedBrowser.name;
            } else {
                device = 'Unknown Browser';
            }

            if (detectedOs.name) {
                device += `, ${detectedOs.name}`;

                if (detectedOs.version) {
                    device += ` ${detectedOs.version}`;
                }
            }

            await fetch('https://geolocation-db.com/json/?callback=')
                .then((res) => res.json())
                .then((data) => {
                    setGeoLocationData({
                        device,
                        ip_address: data?.IPv4 || '',
                        location: `${data?.city || ''}, ${data?.country_name || ''}`,
                        user_agent: userAgent,
                    });
                    isSuccess = true;
                }).catch(() => { });

            if (!isSuccess) {
                await fetch('https://ipapi.co/json').then((res) => res.json()).then((data) => {
                    setGeoLocationData({
                        device,
                        ip_address: data?.ip || '',
                        location: `${data?.city || ''}, ${data?.country_name || ''}`,
                        user_agent: userAgent,
                    });
                    isSuccess = true;
                }).catch(() => { });
            }

            if (!isSuccess) {
                await fetch('/handshake').then((res) => res.json()).then((data) => {
                    setGeoLocationData({
                        device,
                        ip_address: data?.ip || '',
                        location: 'Unknown',
                        user_agent: userAgent,
                    });
                }).catch(() => {
                    setGeoLocationData({
                        device,
                        ip_address: '',
                        location: '',
                        user_agent: userAgent,
                    });
                });
            }
        })();
    }, []);

    const handleSubmit = async (variables) => {
        window.backdropLoader(true);

        let checkOtpOk = false;
        let otpStatus = false;
        try {
            const res = await checkValidateOtp({ variables });

            if (!res.data.checkCustomerValidateOtp) {
                throw new Error(t('login:errorOtp'));
            }

            otpStatus = res.data.checkCustomerValidateOtp;
            checkOtpOk = true;
        } catch (e) {
            window.backdropLoader(false);
            window.toastMessage({
                open: true,
                text: e.message,
                variant: 'error',
            });
        }

        if (checkOtpOk) {
            let internalToken;
            try {
                const res = await getCustomerToken({ variables });

                if (!res.data.internalGenerateCustomerToken.token) {
                    throw new Error(t('login:errorOtp2'));
                }

                internalToken = res.data.internalGenerateCustomerToken.token;
            } catch (e) {
                const errorMessage = e.message.split(':')[0];

                const translateError = () => {
                    // eslint-disable-next-line max-len
                    if (errorMessage === 'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.') {
                        return t('login:signInIncorect');
                    } if (errorMessage === 'The account sign-in was incorrect.') {
                        return t('login:signInIncorect2');
                    } if (errorMessage === 'Maximum failed login attempt reached.') {
                        return t('login:maximumFailed');
                    } if (errorMessage === 'Error checking customer validate otp') {
                        return t('login:errorOtp');
                    } if (errorMessage === 'Error getting customer token') {
                        return t('login:errorOtp2');
                    } if (errorMessage === 'Your account is either not exist, rejected, or not yet approved.') {
                        return t('login:accountNotExist');
                    }
                    return errorMessage;
                };

                window.backdropLoader(false);
                window.toastMessage({
                    open: true,
                    variant: 'error',
                    text: translateError(),
                });
            }

            if (internalToken) {
                try {
                    await setDevice({
                        variables: geoLocationData,
                    });
                } catch (e) {
                    window.toastMessage({
                        open: true,
                        variant: 'error',
                        text: e.message,
                    });
                }

                if (otpStatus === true) {
                    setLogin(1, expiredToken);
                    router.push('/');
                } else {
                    window.localStorage.setItem('GET_EMAIL', variables.email);
                    router.push('/registervendor/otp');
                }
            }
        }
        window.backdropLoader(false);
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            captcha: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required(t('common:required')).matches(regexEmail, t('common:invalidEmail')),
            password: Yup.string().required(t('common:required')),
        }),
        onSubmit: async (values) => {
            const { captcha, ...restValues } = values;
            window.backdropLoader(true);
            if (recaptcha.enable) {
                const token = await window.grecaptcha.execute(recaptcha.sitekey, { action: 'submit' })
                    .then((to_ken) => to_ken);

                fetch('/captcha-validation', {
                    method: 'POST',
                    body: qs.stringify({
                        secret: recaptcha.secretkey,
                        response: token,
                    }),
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' },
                })
                    .then((res) => res.json())
                    .then((json) => {
                        if (json.success) {
                            handleSubmit(restValues);
                        } else {
                            window.backdropLoader(false);
                            window.toastMessage({
                                open: true,
                                variant: 'error',
                                text: t('common:There_is_something_error_while_verifying_captcha'),
                            });
                        }
                    })
                    .catch(() => {
                        window.backdropLoader(false);
                        window.toastMessage({
                            open: true,
                            variant: 'error',
                            text: t('common:Could_not_verify_captcha'),
                        });
                    });
            } else {
                handleSubmit(restValues);
            }
        },
    });

    const contentProps = {
        formik,
        storeLogo,
        t,
        ...props,
    };

    const handleAutomateLogin = async (directToken) => {
        window.backdropLoader(true);
        let internalToken;

        try {
            const res = await getNewToken({ variables: { token: directToken } });
            internalToken = res.data.internalGenerateNewToken.token;
        } catch (e) {
            const errorMessage = e.message.split(':')[0];
            window.backdropLoader(false);
            window.toastMessage({
                open: true,
                variant: 'error',
                text: errorMessage,
            });
        }

        if (internalToken) {
            try {
                await setDevice({
                    variables: geoLocationData,
                });
            } catch (e) {
                window.toastMessage({
                    open: true,
                    variant: 'error',
                    text: e.message,
                });
            }
            setLogin(1, expiredToken);
            router.push('/');
        }
        window.backdropLoader(false);
    };

    React.useEffect(() => {
        if (isAuth) {
            handleAutomateLogin(isAuth);
        }
    }, []);

    return (
        <Content {...contentProps} />
    );
};

const Core = (props) => {
    const { t } = props;
    const pageConfig = {
        title: t('login:pageTitle'),
        header: false,
        sidebar: false,
    };

    const { loading: loadingConfig, data: dataConfig } = getStoreConfig({
        path: 'swiftoms_vendorportal/configuration/enable_vendor_portal',
    });
    const { loading: loadEnabled, data: dataEnabled } = getStoreConfig({
        path: 'msp_securitysuite_recaptcha/frontend/enabled',
    });
    const { loading: loadEnabledReg, data: dataEnabledReg } = getStoreConfig({
        path: 'msp_securitysuite_recaptcha/frontend/enabled_login',
    });
    // const { loading: loadPubKey, data: dataPubKey } = getStoreConfig({
    //     path: 'msp_securitysuite_recaptcha/general/public_key',
    // });
    // const { loading: loadPrivKey, data: dataPrivKey } = getStoreConfig({
    //     path: 'msp_securitysuite_recaptcha/general/private_key',
    // });
    const { loading: loadReCaptcha, data: dataReCaptcha } = getReCaptchaConfig();

    const privateKeyEncrypt = dataReCaptcha?.getReCaptchaConfig.private_key && atob(dataReCaptcha?.getReCaptchaConfig.private_key)?.split('/:');
    const publicKeyEncrypt = dataReCaptcha?.getReCaptchaConfig.public_key && atob(dataReCaptcha?.getReCaptchaConfig.public_key)?.split('/:');

    const dataPrivKey = privateKeyEncrypt && decryptRec(privateKeyEncrypt[0], privateKeyEncrypt[1]);
    const dataPubKey = publicKeyEncrypt && decryptRec(publicKeyEncrypt[0], publicKeyEncrypt[1]);

    React.useEffect(() => {
        BackdropLoad(loadEnabled || loadEnabledReg || loadingConfig || loadReCaptcha);
    }, [loadEnabled, loadEnabledReg, loadingConfig, loadReCaptcha]);

    if (loadEnabled || loadEnabledReg || loadingConfig || loadReCaptcha) {
        return <Layout pageConfig={pageConfig} />;
    }
    const contentProps = {
        ...props,
        recaptcha: {
            enable: dataEnabled.getStoreConfig === '1' && dataEnabledReg.getStoreConfig === '1',
            sitekey: dataPubKey,
            secretkey: dataPrivKey,
        },
        dataConfig: dataConfig && dataConfig.getStoreConfig && dataConfig.getStoreConfig === '1',
    };

    return (
        <Layout pageConfig={pageConfig}>
            <ContentWrapper {...contentProps} />
        </Layout>
    );
};

export default Core;
