const crypto = require('crypto');
const {
    encryption: { algorithm },
} = require('../../../../../swift-server.config');

const key = 'x!A%D*G-KaPdSgVkYp3s5v8y/B?E(H+M';
const ivs = key.substr(0, 16);

const encryptRec = (text) => {
    const cipher = crypto.createCipheriv(algorithm, key, ivs);
    let crypted = cipher.update(text, 'utf8', 'base64');
    crypted += cipher.final('base64');
    return crypted;
};
const decryptRec = (text, iv) => {
    const decipher = crypto.createDecipheriv(algorithm, key, iv);
    let dec = decipher.update(text, 'base64', 'utf8');
    dec += decipher.final('utf8');
    return dec;
};

module.exports = {
    decryptRec,
    encryptRec,
};
