import { makeStyles } from '@material-ui/core/styles';
import {
    BLUE_CYAN, BLACK, RED, COLOR_BORDER, ORANGE_PRIMARY, WHITE,
} from '@theme_color';

const useStyles = makeStyles((theme) => ({
    loginContainer: {
        background: '#FFFFFF',
        [theme.breakpoints.up('sm')]: {
            margin: '0 auto',
            width: '100%',
            height: '100%',
            position: 'relative',
        },
    },
    textTitle: {
        fontSize: 28,
        color: `${BLACK}`,
        textAlign: 'center',
        marginBottom: 30,
    },
    textInput: {
        width: '100%',
        marginTop: 5,
        '& .MuiFormHelperText-root': {
            textAlign: 'right',
        },
        '& .MuiInputBase-input': {
            padding: 10,
        },
        '& fieldset': {
            borderColor: `${COLOR_BORDER}`,
        },
    },
    formField: {
        padding: 0,
        paddingBottom: 25,
        position: 'relative',
    },
    headerLogin: {
        [theme.breakpoints.down('xs')]: {
            margin: '20px 20px 0 20px',
        },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    containLeft: {
        [theme.breakpoints.up('sm')]: {
            width: '48%',
            float: 'left',
            marginTop: 26,
            marginLeft: 21,
        },
    },
    containRight: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            width: '49%',
            float: 'right',
            height: '100%',
        },
    },
    rightImg: {
        width: '100%',
        objectFit: 'cover',
        [theme.breakpoints.up('sm')]: {
            height: '100%',
        },
    },
    loginContent: {
        maxWidth: 307,
        [theme.breakpoints.up('sm')]: {
            left: '5%',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
        },
        [theme.breakpoints.up('md')]: {
            left: '9%',
        },
        [theme.breakpoints.up('lg')]: {
            left: '15%',
        },
        [theme.breakpoints.down('xs')]: {
            margin: '20% auto',
            maxWidth: 280,
        },
    },
    btnLogin: {
        padding: 6,
        width: '100%',
        boxShadow: 'none',
    },
    btnLoginText: {
        color: '#FFFFFF',
        padding: '2px 121px',
        letterSpacing: 2,
        fontSize: 20,
    },
    btnTextForgot: {
        display: 'block',
        marginTop: 26,
        textAlign: 'center',
        fontSize: 14,
        color: `${ORANGE_PRIMARY}`,
    },
    btnVendor: {
        marginTop: 20,
        background: `${BLUE_CYAN}`,
        border: `1px solid ${BLUE_CYAN}`,
        padding: 6,
        width: '100%',
        boxShadow: 'none',
        '&:hover': {
            background: `${BLUE_CYAN}`,
        },
    },
    btnVendorText: {
        color: `${WHITE}`,
        letterSpacing: 1,
        fontSize: 20,
        textTransform: 'capitalize',
    },
    label: {
        color: `${BLACK}`,
        position: 'relative',
        fontWeight: 700,
    },
    labelRequired: {
        '&::after': {
            content: "'*'",
            display: 'block',
            position: 'absolute',
            top: -9,
            right: -9,
            color: `${RED}`,
            fontSize: 20,
        },
    },
}));

export default useStyles;
